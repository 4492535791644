import "./spinner.css";

const bars = Array(12).fill(0);

export const Loader = () => {
  return (
    <div className="loader-loading-wrapper">
      <div className="loader-spinner">
        {bars.map((_, i) => (
          <div className="loader-loading-bar" key={`spinner-bar-${i}`} />
        ))}
      </div>
    </div>
  );
};

export default Loader;
