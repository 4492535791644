"use client";

import Image from "next/image";
import * as Clerk from "@clerk/elements/common";
import * as SignIn from "@clerk/elements/sign-in";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Icons } from "@/components/ui/icons";
import { cn } from "@/lib/utils";
import Spinner from "@/components/shared/spinner";

export default function Page() {
  return (
    <main className="flex min-h-screen w-full">
      <div className="container relative grid flex-col items-center justify-center lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div className="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
          <div className="absolute inset-0 bg-[url('/hindsight_bg.png')] bg-cover" />
          <div className="relative z-20 flex items-center text-lg font-medium">
            <Image
              src="/logo_dark.png"
              alt="Hindsight"
              width={125}
              height={125}
            />
          </div>
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <SignIn.Root>
              <Clerk.Loading>
                {(isGlobalLoading) => (
                  <>
                    <SignIn.Step name="start">
                      <Card className="w-full border-none sm:w-96">
                        <CardHeader>
                          <CardTitle>Sign in to Hindsight</CardTitle>
                          <CardDescription>
                            Welcome back! Please sign in to continue
                          </CardDescription>
                        </CardHeader>
                        <CardContent className="grid gap-y-4">
                          <div className="grid grid-cols-2 gap-x-4">
                            <Clerk.Connection name="microsoft" asChild>
                              <Button
                                size="sm"
                                variant="outline"
                                type="button"
                                disabled={isGlobalLoading}
                              >
                                <Clerk.Loading scope="provider:microsoft">
                                  {(isLoading) =>
                                    isLoading ? (
                                      <Spinner />
                                    ) : (
                                      <>
                                        <Image
                                          src="/microsoft.svg"
                                          alt="Microsoft"
                                          width={16}
                                          height={16}
                                          className="mr-2"
                                        />
                                        Microsoft
                                      </>
                                    )
                                  }
                                </Clerk.Loading>
                              </Button>
                            </Clerk.Connection>
                            <Clerk.Connection name="google" asChild>
                              <Button
                                size="sm"
                                variant="outline"
                                type="button"
                                disabled={isGlobalLoading}
                              >
                                <Clerk.Loading scope="provider:google">
                                  {(isLoading) =>
                                    isLoading ? (
                                      <Spinner />
                                    ) : (
                                      <>
                                        <Image
                                          src="/google.svg"
                                          alt="Google"
                                          width={18}
                                          height={18}
                                          className="mr-2"
                                        />{" "}
                                        Google
                                      </>
                                    )
                                  }
                                </Clerk.Loading>
                              </Button>
                            </Clerk.Connection>
                          </div>
                          <p className="flex items-center gap-x-3 text-sm text-muted-foreground before:h-px before:flex-1 before:bg-border after:h-px after:flex-1 after:bg-border">
                            or
                          </p>
                          <Clerk.Field name="identifier" className="space-y-2">
                            <Clerk.Label asChild>
                              <Label>Email address</Label>
                            </Clerk.Label>
                            <Clerk.Input type="email" required asChild>
                              <Input />
                            </Clerk.Input>
                            <Clerk.FieldError className="block text-sm text-destructive" />
                          </Clerk.Field>
                        </CardContent>
                        <CardFooter>
                          <div className="grid w-full gap-y-4">
                            <SignIn.Action submit asChild>
                              <Button disabled={isGlobalLoading}>
                                <Clerk.Loading>
                                  {(isLoading) => {
                                    return isLoading ? <Spinner /> : "Continue";
                                  }}
                                </Clerk.Loading>
                              </Button>
                            </SignIn.Action>

                            <Button variant="link" size="sm" asChild>
                              <Link href="/sign-up">
                                Don&apos;t have an account? Sign up
                              </Link>
                            </Button>
                          </div>
                        </CardFooter>
                      </Card>
                    </SignIn.Step>

                    <SignIn.Step name="choose-strategy">
                      <Card className="w-full sm:w-96">
                        <CardHeader>
                          <CardTitle>Use another method</CardTitle>
                          <CardDescription>
                            Facing issues? You can use any of these methods to
                            sign in.
                          </CardDescription>
                        </CardHeader>
                        <CardContent className="grid gap-y-4">
                          <SignIn.SupportedStrategy name="email_code" asChild>
                            <Button
                              type="button"
                              variant="link"
                              disabled={isGlobalLoading}
                            >
                              Email code
                            </Button>
                          </SignIn.SupportedStrategy>
                          <SignIn.SupportedStrategy name="password" asChild>
                            <Button
                              type="button"
                              variant="link"
                              disabled={isGlobalLoading}
                            >
                              Password
                            </Button>
                          </SignIn.SupportedStrategy>
                        </CardContent>
                        <CardFooter>
                          <div className="grid w-full gap-y-4">
                            <SignIn.Action navigate="previous" asChild>
                              <Button disabled={isGlobalLoading}>
                                <Clerk.Loading>
                                  {(isLoading) => {
                                    return isLoading ? <Spinner /> : "Go back";
                                  }}
                                </Clerk.Loading>
                              </Button>
                            </SignIn.Action>
                          </div>
                        </CardFooter>
                      </Card>
                    </SignIn.Step>

                    <SignIn.Step name="verifications">
                      <SignIn.Strategy name="password">
                        <Card className="w-full sm:w-96">
                          <CardHeader>
                            <CardTitle>Check your email</CardTitle>
                            <CardDescription>
                              Enter the verification code sent to your email
                            </CardDescription>
                            <p className="text-sm text-muted-foreground">
                              Welcome back <SignIn.SafeIdentifier />
                            </p>
                          </CardHeader>
                          <CardContent className="grid gap-y-4">
                            <Clerk.Field name="password" className="space-y-2">
                              <Clerk.Label asChild>
                                <Label>Password</Label>
                              </Clerk.Label>
                              <Clerk.Input type="password" asChild>
                                <Input />
                              </Clerk.Input>
                              <Clerk.FieldError className="block text-sm text-destructive" />
                            </Clerk.Field>
                          </CardContent>
                          <CardFooter>
                            <div className="grid w-full gap-y-4">
                              <SignIn.Action submit asChild>
                                <Button disabled={isGlobalLoading}>
                                  <Clerk.Loading>
                                    {(isLoading) => {
                                      return isLoading ? (
                                        <Spinner />
                                      ) : (
                                        "Continue"
                                      );
                                    }}
                                  </Clerk.Loading>
                                </Button>
                              </SignIn.Action>
                              <SignIn.Action navigate="choose-strategy" asChild>
                                <Button type="button" size="sm" variant="link">
                                  Use another method
                                </Button>
                              </SignIn.Action>
                            </div>
                          </CardFooter>
                        </Card>
                      </SignIn.Strategy>

                      <SignIn.Strategy name="email_code">
                        <Card className="w-full sm:w-96">
                          <CardHeader>
                            <CardTitle>Check your email</CardTitle>
                            <CardDescription>
                              Enter the verification code sent to your email
                            </CardDescription>
                            <p className="text-sm text-muted-foreground">
                              Welcome back <SignIn.SafeIdentifier />
                            </p>
                          </CardHeader>
                          <CardContent className="grid gap-y-4">
                            <Clerk.Field name="code">
                              <Clerk.Label className="sr-only">
                                Email verification code
                              </Clerk.Label>
                              <div className="grid items-center justify-center gap-y-2">
                                <div className="flex justify-center text-center">
                                  <Clerk.Input
                                    type="otp"
                                    autoSubmit
                                    className="flex justify-center has-[:disabled]:opacity-50"
                                    render={({ value, status }) => {
                                      return (
                                        <div
                                          data-status={status}
                                          className="relative flex h-9 w-9 items-center justify-center border-y border-r border-input text-sm shadow-sm transition-all first:rounded-l-md first:border-l last:rounded-r-md data-[status=cursor]:ring-1 data-[status=selected]:ring-1 data-[status=cursor]:ring-ring data-[status=selected]:ring-ring"
                                        >
                                          {value}
                                        </div>
                                      );
                                    }}
                                  />
                                </div>
                                <Clerk.FieldError className="block text-center text-sm text-destructive" />
                                <SignIn.Action
                                  asChild
                                  resend
                                  className="text-muted-foreground"
                                  fallback={({ resendableAfter }) => (
                                    <Button variant="link" size="sm" disabled>
                                      Didn&apos;t receive a code? Resend (
                                      <span className="tabular-nums">
                                        {resendableAfter}
                                      </span>
                                      )
                                    </Button>
                                  )}
                                >
                                  <Button variant="link" size="sm">
                                    Didn&apos;t receive a code? Resend
                                  </Button>
                                </SignIn.Action>
                              </div>
                            </Clerk.Field>
                          </CardContent>
                          <CardFooter>
                            <div className="grid w-full gap-y-4">
                              <SignIn.Action submit asChild>
                                <Button disabled={isGlobalLoading}>
                                  <Clerk.Loading>
                                    {(isLoading) => {
                                      return isLoading ? (
                                        <Spinner />
                                      ) : (
                                        "Continue"
                                      );
                                    }}
                                  </Clerk.Loading>
                                </Button>
                              </SignIn.Action>
                              <SignIn.Action navigate="choose-strategy" asChild>
                                <Button size="sm" variant="link">
                                  Use another method
                                </Button>
                              </SignIn.Action>
                            </div>
                          </CardFooter>
                        </Card>
                      </SignIn.Strategy>
                    </SignIn.Step>
                  </>
                )}
              </Clerk.Loading>
            </SignIn.Root>
          </div>
        </div>
      </div>
    </main>
  );
}
